<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">学生请假记录</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="班级" prop="classId">
        <el-select-tree
          size="mini"
          :props="props"
          :options="treeData"
          v-model="queryModel.classId"
          height="200"
        ></el-select-tree>
      </el-form-item>
      <el-form-item label="请假类型" prop="type">
        <el-select
          v-model="queryModel.leaveType"
          size="mini"
          filterable
          placeholder="请选择"
          style="width:100px"
        >
          <el-option v-for="item in leaveTypeList" :key="item.id" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审批状态" prop="status">
        <!--0-未处理，1-已批准，2-已拒绝-->
        <el-select
          v-model="queryModel.status"
          size="mini"
          filterable
          placeholder="请选择"
          style="width:100px"
        >
          <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请时间">
        <el-date-picker
            v-model="queryModel.requestTimeRange"
            type="daterange"
            size="mini"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            value-format="yyyy-MM-dd"
            >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="审核时间">
        <el-date-picker
            v-model="queryModel.auditTimeRange"
            type="daterange"
            size="mini"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            value-format="yyyy-MM-dd"
            >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <div class="button-group">
      <div class="title">请假记录列表</div>
      <el-button type="success" size="small" plain icon="el-icon-download" @click="exportXls()">批量导出</el-button>
    </div>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
    >
      <el-table-column type="expand">
        <template slot-scope="{row}">
          <el-form label-position="left" class="demo-table-expand">
            <el-form-item label="申请家长:">
              <span>{{row.parentName }}</span>
            </el-form-item>
            <el-form-item label="家长手机号:">
              <span>{{row.parentPhone }}</span>
            </el-form-item>
            <el-form-item label="请假类型:">
              <span>{{row.leaveTypeN }}</span>
            </el-form-item>
            <el-form-item label="请假时间:">
              <span>{{row.time }}</span>
            </el-form-item>
            <el-form-item label="请假事由:">
              <span>{{row.reason }}</span>
            </el-form-item>
            <el-form-item label="上传附件:">
              <span>{{row.imageUrl }}</span>
            </el-form-item>
            <el-form-item label="班主任:">
              <span>{{row.teacherName }}</span>
            </el-form-item>
            <el-form-item label="审核意见:">
              <span>{{row.remark }}</span>
            </el-form-item>
            <el-form-item label="审核时间:">
              <span>{{row.updateTime }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="申请时间" :sortable="false" width="180"></el-table-column>
      <el-table-column prop="name" label="学生姓名" :sortable="false" width="150"></el-table-column>
      <el-table-column prop="idCard" label="身份证号" :sortable="false" width="180"></el-table-column>
      <el-table-column prop="studentNumber" label="学号" :sortable="false" width="180"></el-table-column>
      <el-table-column prop="className" label="所属班级" :sortable="false" width="180"></el-table-column>
      <el-table-column prop="teacherName" label="班主任" :sortable="false" width="180"></el-table-column>
      <!-- <el-table-column prop="parentName" label="申请家长" :sortable="false" width="180"></el-table-column>
      <el-table-column prop="parentPhone" label="家长手机号" :sortable="false" width="180"></el-table-column> -->
      <el-table-column prop="leaveTypeN" label="请假类型" :sortable="false" width="180"></el-table-column>
      <el-table-column prop="statusN" label="审批状态" :sortable="false" width="180"></el-table-column>
      <!-- <el-table-column prop="time" label="请假时间" :sortable="false" width="180"></el-table-column>
      <el-table-column prop="reason" label="请假事由" :sortable="false" width="180"></el-table-column>
      <el-table-column prop="imageUrl" label="上传附件" :sortable="false" width="180"></el-table-column> -->
      <!-- <el-table-column prop="remark" label="审核意见" :sortable="false" width="180"></el-table-column> -->
      <el-table-column prop="updateTime" label="审核时间" :sortable="false" width="180"></el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>
<script>
import Constant from "@/constant";
import requestForLeaveApi from "@/api/business/requestForLeave";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import companyInfoApi from "@/api/base/companyInfo";

import SelectTree from "@/components/SelectTree";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "business-requestForLeave-list",
  data() {
    var self = this;

    return {
      queryModel: {
        classId: "",
        leaveType: "",
        status:"",
        requestTimeRange: ['',''],
        auditTimeRange: ['','']
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      pageSizeList: [10, 20, 30],
      leaveTypeList: [],
      //0-未处理，1-已批准，2-已拒绝
      statusList: [
        {id: 0, name: "未处理"},
        {id: 1, name: "已批准"},
        {id: 2, name: "已拒绝"}
      ],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      }
    }
  },
  created() {
    var formData = new FormData();
    formData.append("catalogName", "请假类型");

    dataDictionaryApi.findByCatalogName(formData).then((response)=>{
      var jsonData = response.data;
      this.leaveTypeList = jsonData.data;
    });

    this.loadTree();

    this.changePage(1);
  },
  methods: {
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    showStatusName(status) {
      var list = this.statusList.filter(item=>item.id == status);

      if(list.length>0){
        return list[0].name;
      }
      else{
        return "";
      }
    },
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    changePage(pageIndex,exportFlag) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);

      if(self.queryModel.classId!=null){
        formData.append("classId", self.queryModel.classId);
      }

      
      if(self.queryModel.leaveType!=null){
        formData.append("leaveType", self.queryModel.leaveType);
      }


      if(self.queryModel.status!=null){
        formData.append("status", self.queryModel.status);
      }

      if(self.queryModel.requestTimeRange[0] != null){
        formData.append("requestStartTime", self.queryModel.requestTimeRange[0]);
      }

      if(self.queryModel.requestTimeRange[1] != null){
        formData.append("requestEndTime", self.queryModel.requestTimeRange[1]);
      }

      if(self.queryModel.auditTimeRange[0] != null){
        formData.append("auditStartTime", self.queryModel.auditTimeRange[0]);
      }

      if(self.queryModel.auditTimeRange[1] != null){
        formData.append("auditEndTime", self.queryModel.auditTimeRange[1]);
      }

      if(exportFlag!=null){
        formData.append("exportFlag", exportFlag);
        formData.append("pageSize", 10000);
      }
      else{
        formData.append("pageSize", self.pageSize);
      }

      requestForLeaveApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data;

          if(jsonData.result) {
            if(exportFlag){
              //导出
              self.$message({
                showClose: true,
                type: "success",
                message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                dangerouslyUseHTMLString: true,
                duration: 30000
              });
            }
            else{
              //分页查看
              var page = jsonData.data;

              self.tableData = page.data;
              self.totalPages = page.totalPages;
              self.totalElements = page.recordsTotal;
            }
          }
          else{
            self.$message.warning(jsonData.message);
          }
        })
        .catch(error => {
          self.loading = false;
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.changePage(1);
    },
    exportXls() {
      this.changePage(1,true);
    }
  },
  components: {
    "el-select-tree": SelectTree
  }
}
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  padding-left: 10px;
  padding-right: 20px;
  display: flex;
  box-sizing:border-box;
  flex-direction: row;
  justify-content: space-between;
  width:100%;

  .title{
    font-size:16px;
    font-weight: bold;
  }
}
</style>